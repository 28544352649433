@import 'src/assets/scss/_variables.scss';

.upload-sec{
    height: 220px;
    background-color: #1e88e60f;
    border: 1px dashed $primary-color;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .upload-icon-customization{
        font-size: 90px;
        color: $primary-color;
    }
    .fileDrop{
        position: absolute;
        top: 0;
        left: 0;
        height: 180px;
        width: 100%;
        opacity: 0;
        cursor: pointer;
      }
      .upload-text {
        font-weight: 600;
        font-size: 10px;
        color: $primary-color;
      }
}
.upload-text {
  font-weight: 600;
  font-size: 10px;
  color: #1e88e6;
}
.upload-img{
  background-image: url('./../images/upload.png');
  width: 100px;
  height: 100px;
  position: relative;
  display: block;
  z-index: 11;
  background-size: contain;
  background-repeat: no-repeat;
}